import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HubHttpPaginationResponse } from '@core/interfaces/HubResponse';
import { AttachLabelsRequest, HubLabel } from '@core/interfaces/label';
import { HubEntityType } from '@core/interfaces/hub-entity-type';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  private readonly baseUrl = `${environment.BACKEND_DOMAIN}/api/v1/labels`;

  constructor(private http: HttpClient) {}

  all(
    elementId?: string,
    elementType?: HubEntityType,
  ): Observable<HubHttpPaginationResponse<HubLabel>> {
    let params = new HttpParams();
    if (elementId) {
      params = params.set('elementId', elementId);
    }
    if (elementType) {
      params = params.set('elementType', elementType.toString());
    }

    return this.http.get<HubHttpPaginationResponse<HubLabel>>(this.baseUrl, { params });
  }

  create(body: Partial<HubLabel>): Observable<HubLabel> {
    return this.http.post<HubLabel>(this.baseUrl, body);
  }

  update(body: Partial<HubLabel>, id: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${id}`, body);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  attachLabels(body: AttachLabelsRequest): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/attach`, body);
  }
}
